import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../helpers'
import { FetchErrorInterface, UserInterface } from '../interfaces'

interface UserSuccessResponseInterface {
  data: UserInterface
  statusTxt: string
  statusCode: number
}

interface UserErrorResponseInterface {
  message?: string
}

export function useUser({ config }: { config?: SWRConfiguration } = {}) {
  const {
    data: user,
    mutate: mutateUser,
    error
  } = useSWR<UserInterface, FetchErrorInterface<UserErrorResponseInterface>>(
    `${process.env.REACT_APP_ENDPOINT}/dashboard/user`,
    userFetcher,
    config
  )

  const userData: UserInterface | undefined = user
    ? {
        ...user,
        billing: user.billing?.paymentMethods
          ? {
              ...user.billing,
              hasPaymentSetup: user.billing.hasPaymentSetup || user.billing.paymentMethods.length > 0
            }
          : user.billing
      }
    : undefined

  return {
    isLoading: !user && !error,
    mutateUser,
    user: userData,
    error
  }
}

async function userFetcher(url: string) {
  return fetcher<UserSuccessResponseInterface>(url).then((userSuccessResponse) => userSuccessResponse.data)
}
