import { forwardRef } from 'react'
import { AnimatePresence, motion } from 'motion/react'
import * as RadixRadioGroup from '@radix-ui/react-radio-group'

import { cn } from '../../helpers'

/**
 * Component adapted from shadcn/ui
 * Visit https://ui.shadcn.com/docs/components/radio-group for more info and docs
 */
const RadioGroup = forwardRef<
  React.ElementRef<typeof RadixRadioGroup.Root>,
  React.ComponentPropsWithoutRef<typeof RadixRadioGroup.Root>
>(({ className, ...props }, ref) => {
  return <RadixRadioGroup.Root className={cn('grid gap-3', className)} {...props} ref={ref} />
})
RadioGroup.displayName = RadixRadioGroup.Root.displayName

const RadioGroupItem = forwardRef<
  React.ElementRef<typeof RadixRadioGroup.Item>,
  React.ComponentPropsWithoutRef<typeof RadixRadioGroup.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadixRadioGroup.Item
      ref={ref}
      className={cn(
        'aspect-square h-6 w-6 rounded-full border border-gray-400 focus:outline-none focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-black disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-purple-400 data-[state=checked]:bg-purple-400',
        className
      )}
      {...props}
    >
      <AnimatePresence initial={false}>
        <RadixRadioGroup.Indicator className="flex items-center justify-center">
          <motion.div
            className="h-2 w-2 rounded-full bg-white"
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
          ></motion.div>
        </RadixRadioGroup.Indicator>
      </AnimatePresence>
    </RadixRadioGroup.Item>
  )
})
RadioGroupItem.displayName = RadixRadioGroup.Item.displayName

export { RadioGroup, RadioGroupItem }
