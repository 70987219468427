import { useSWRConfig } from 'swr'
import { useCallback } from 'react'
import useSWRInfinite from 'swr/infinite'
import { useLocation } from 'react-router'

import { fetcher } from '../helpers'
import { QUERY_PARAMS } from '../constants'
import { FetchErrorInterface } from '../interfaces'
import { DEFAULT_IMAGE_LIBRARY_SORT_ORDER } from '../components/ImageLibraryActions'

export type ImageLibraryImageMimeType = 'image/jpeg' | 'image/png' | 'application/pdf'

export type ImageLibraryImageType = 'artwork' | 'packing' | 'book'

export type ImageLibraryFileType = 'png' | 'jpg' | 'pdf (multi-page)' | 'pdf (single page)'

export interface ImageLibraryImageInterface {
  date_created: string
  date_last_modified: string
  date_last_used: string
  file_type: ImageLibraryFileType
  image_type: ImageLibraryImageType
  is_truncated: boolean
  mime_type: ImageLibraryImageMimeType
  name: string
  original_url: string
  pdf_aspect_ratio: number
  pdf_page_count: number
  public_id: string
  pixel_height?: number
  pixel_width?: number
  tags: string[]
  thumbnail_urls: {
    large?: string
    small?: string
  }
  upload_status: 'success' | 'pending' | 'aborted' | 'failed'
  url: string
}

const IMAGE_LIBRARY_PAGE_SIZE = 50

export function useImageLibraryImages() {
  const baseUrl = `${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/images/`
  const { search } = useLocation()
  const { cache } = useSWRConfig()

  const { data, error, mutate, size, setSize } = useSWRInfinite<
    ImageLibraryImageInterface[],
    FetchErrorInterface<{ detail?: string }>
  >(
    (pageIndex: number, previousPageData: ImageLibraryImageInterface[]) => {
      const hasReachedEnd = previousPageData && previousPageData.length === 0
      if (hasReachedEnd) {
        return null
      }

      const windowSearchParams = new URLSearchParams(search)
      const imageLibraryRequestParams = new URLSearchParams()
      imageLibraryRequestParams.append('limit', IMAGE_LIBRARY_PAGE_SIZE.toString())
      imageLibraryRequestParams.append('skip', (pageIndex * IMAGE_LIBRARY_PAGE_SIZE).toString())
      const imageLibraryOrderByParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.ORDER_BY)
      imageLibraryRequestParams.append('order_by', imageLibraryOrderByParam || DEFAULT_IMAGE_LIBRARY_SORT_ORDER)

      const imageLibrarySearchParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.SEARCH)
      if (imageLibrarySearchParam) {
        imageLibraryRequestParams.append('name', imageLibrarySearchParam)
      }

      const imageLibraryFilterTypeParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      if (imageLibraryFilterTypeParam) {
        imageLibraryRequestParams.append('image_types', imageLibraryFilterTypeParam)
      }

      const imageLibraryFileTypesParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      if (imageLibraryFileTypesParam) {
        imageLibraryRequestParams.append('file_types', imageLibraryFileTypesParam)
      }

      const imageLibraryFilterOrientationParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION)
      if (imageLibraryFilterOrientationParam) {
        imageLibraryRequestParams.append('orientations', imageLibraryFilterOrientationParam)
      }

      const imageLibraryFilterTagParam = windowSearchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TAG)
      if (imageLibraryFilterTagParam) {
        imageLibraryRequestParams.append('tags', imageLibraryFilterTagParam)
      }

      return baseUrl + `?${imageLibraryRequestParams.toString()}`
    },
    fetcher,
    { revalidateAll: true }
  )

  const clearPagesCache = useCallback(() => {
    const keysToDelete = []

    // Note: Test when updating SWR
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    for (const key of (cache as any)?.keys?.()) {
      const cacheKey = key as string | undefined
      const isCacheKeyForNonInitialPage =
        cacheKey?.includes(baseUrl) && cacheKey.includes('skip') && !cacheKey.includes('skip=0')

      if (isCacheKeyForNonInitialPage) {
        keysToDelete.push(cacheKey)
      }
    }

    for (const keyToDelete of keysToDelete) {
      cache.delete(keyToDelete)
    }
  }, [baseUrl, cache])

  return {
    imageLibraryFetchError: error,
    imageLibraryPages: data,
    imageLibraryPagesToLoadSize: size,
    isLoadingImageLibraryImages: !data && !error,
    clearImageLibraryPagesCache: clearPagesCache,
    mutateImageLibraryImages: mutate,
    setImageLibraryPagesToLoadSize: setSize
  }
}
