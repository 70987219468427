import { useState } from 'react'

import Button from '../../Button'
import { cn } from '../../../helpers'
import { Checkbox } from '../../Checkbox'
import OverlayPortal from '../../OverlayPortal'
import { StatusType } from '../../../interfaces'
import ConfirmationModal from '../../ConfirmationModal'
import { IconCheckMark } from '../../InlineMenu/components'
import { RadioGroup, RadioGroupItem } from '../../RadioGroup'
import { TabContentActionsValueType } from './ProductLibraryTabContentActions'

export function ProductLibraryTabContentEditOptions({
  actionModalToOpen,
  onSetActionModalToOpen
}: {
  actionModalToOpen: TabContentActionsValueType | null
  onSetActionModalToOpen: (isOption: TabContentActionsValueType | null) => void
}) {
  const [variantUpdateStatus, setVariantUpdateStatus] = useState<StatusType>('idle')
  const [sameOptionsForAllVariants, setSameOptionsForAllVariants] = useState<boolean>(false)
  const [selectedFrames, setSelectedFrames] = useState<string[]>(['Antique silver', 'Brown', 'Natural'])

  function handleUpdateVariants() {
    console.log('handle variants')
    setVariantUpdateStatus('idle')
  }

  function handleOpenChange() {
    onSetActionModalToOpen(null)
  }

  return (
    <>
      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        closeOnEscape={variantUpdateStatus !== 'loading'}
        closeOnInteractionOutside={variantUpdateStatus !== 'loading'}
        contentClassName="overflow-auto max-h-60vh"
        continueButton={{ text: 'Save options', disabled: selectedFrames.length === 0 }}
        isLoading={variantUpdateStatus === 'loading'}
        title="Variant options"
        open={actionModalToOpen === 'edit-options'}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange()}
        onContinue={handleUpdateVariants}
      >
        <div>
          <div className="font-medium">The following variants are available for 11x14&quot; classic frames</div>
          <div className="text-xs text-gray-600">GLOBAL-CFPM-11X14</div>

          <Divider />
          <Frames selectedFrames={selectedFrames} setSelectedFrames={setSelectedFrames} />
          <Divider />
          <Edges />
          <Divider />

          <div className={cn('mb-6 text-red-800', selectedFrames.length > 0 && 'invisible')}>
            Please select at least one frame colour
          </div>

          <span className="flex w-full items-center gap-4">
            <Checkbox
              checked={sameOptionsForAllVariants}
              id="useForAllOptions"
              onCheckedChange={(checked) => setSameOptionsForAllVariants(Boolean(checked))}
            />

            <label htmlFor="useForAllOptions">Use these same options for all variants</label>
          </span>
        </div>
      </ConfirmationModal>

      {variantUpdateStatus === 'loading' && <OverlayPortal />}
    </>
  )
}

function Divider({ className }: { className?: string }) {
  return <div className={cn('my-10 h-[1px] w-full bg-gray-200', className)} />
}

const TEMP_FRAMES: { name: string; url: string }[] = [
  {
    name: 'Antique gold',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-antique-gold.jpg'
  },
  {
    name: 'Antique silver',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-antique-silver.jpg'
  },
  {
    name: 'Black',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-black.png'
  },
  {
    name: 'Brown',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-brown.png'
  },
  {
    name: 'Dark grey',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-dark-grey.png'
  },
  {
    name: 'Light grey',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-light-grey.png'
  },
  {
    name: 'Natural',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-natural.jpg'
  },
  {
    name: 'White',
    url: 'https://www.prodigi.com/img/products/swatches/frame-classic-white.png'
  }
]

function Frames({
  selectedFrames,
  setSelectedFrames
}: {
  selectedFrames: string[]
  setSelectedFrames: React.Dispatch<React.SetStateAction<string[]>>
}) {
  return (
    <>
      <span className="my-8 flex w-full items-center justify-between">
        <span className="text-xl font-medium">Frames</span>
        <div className="flex gap-1">
          <Button size="sm" variant="text" onClick={() => setSelectedFrames(TEMP_FRAMES.map((frame) => frame.name))}>
            Select all
          </Button>
          /
          <Button size="sm" variant="text" onClick={() => setSelectedFrames([])}>
            None
          </Button>
        </div>
      </span>

      <div className="flex flex-wrap gap-8 px-2">
        {TEMP_FRAMES.map((frame) => (
          <FrameColour
            key={frame.name}
            frame={frame}
            selectedFrames={selectedFrames}
            setSelectedFrames={setSelectedFrames}
          />
        ))}
      </div>
    </>
  )
}

function FrameColour({
  frame,
  selectedFrames,
  setSelectedFrames
}: {
  frame: { name: string; url: string }
  selectedFrames: string[]
  setSelectedFrames: React.Dispatch<React.SetStateAction<string[]>>
}) {
  const checked = selectedFrames.includes(frame.name)

  return (
    <button
      className="relative flex w-28 flex-col items-center border-0"
      onClick={() => {
        if (!checked) {
          setSelectedFrames([...selectedFrames, frame.name])
        } else {
          const newOptions = selectedFrames.filter((option) => option !== frame.name)
          setSelectedFrames(newOptions)
        }
      }}
    >
      <div className="h-24 w-24 rounded-full border border-gray-200" style={{ backgroundImage: `url(${frame.url})` }} />
      <span className={cn('mt-2 text-center text-sm text-gray-600', checked && 'text-black')}>{frame.name}</span>
      <div
        className={cn(
          'absolute left-0 top-0 flex h-9 w-9 items-center justify-center rounded-full bg-transparent',
          checked && 'bg-green-700'
        )}
      >
        <IconCheckMark className="h-5 w-5 text-white" dataTest="checkbox-check" isVisible={checked} strokeWidth={5} />
      </div>
    </button>
  )
}

type EdgeType = 'standard' | 'image'
type EdgeOption = 'Image wrap' | 'White edge' | 'Black edge' | 'Mirror wrap'

const STANDARD_EDGES: EdgeOption[] = ['White edge', 'Black edge', 'Mirror wrap']
const IMAGE_WRAP_EDGES: EdgeOption[] = ['Image wrap']

function Edges() {
  const [selectedEdge, setSelectedEdge] = useState<EdgeType>('image')
  const [selectedEdgeOptions, setSelectedEdgeOptions] = useState<EdgeOption[]>(IMAGE_WRAP_EDGES)

  function handleEdgeChange(selectedEdge: EdgeType) {
    if (selectedEdge === 'image') {
      setSelectedEdge('image')
      setSelectedEdgeOptions(IMAGE_WRAP_EDGES)
    } else {
      setSelectedEdge('standard')
      setSelectedEdgeOptions(STANDARD_EDGES)
    }
  }

  return (
    <>
      <span className="my-8 text-xl font-medium">Edges</span>
      <RadioGroup
        className="mt-4 gap-12"
        value={selectedEdge}
        onValueChange={(newValue) => {
          handleEdgeChange(newValue as EdgeType)
        }}
      >
        <Edge
          disabled={selectedEdge === 'image'}
          edges={STANDARD_EDGES}
          heading="Standard edges"
          id="standard"
          label="The full image is shown on the front of the product"
          selectedEdgeOptions={selectedEdgeOptions}
          setSelectedEdgeOptions={setSelectedEdgeOptions}
        />

        <Edge
          disabled
          edges={IMAGE_WRAP_EDGES}
          heading="Image wrap"
          id="image"
          label="The edges of the image wrap around the sides of the frame. The image on the front of the product will be
              cropped"
          selectedEdgeOptions={selectedEdgeOptions}
          setSelectedEdgeOptions={setSelectedEdgeOptions}
        />
      </RadioGroup>
    </>
  )
}

function Edge({
  disabled,
  edges,
  heading,
  id,
  label,
  selectedEdgeOptions,
  setSelectedEdgeOptions
}: {
  disabled: boolean
  edges: EdgeOption[]
  heading: string
  id: EdgeType
  label: string
  selectedEdgeOptions: EdgeOption[]
  setSelectedEdgeOptions: React.Dispatch<React.SetStateAction<EdgeOption[]>>
}) {
  return (
    <div className="flex items-start gap-4">
      <RadioGroupItem className="mt-1" id={id} value={id} />
      <div>
        <label className="font-medium" htmlFor={id}>
          {heading}
        </label>
        <p className="mb-2 text-sm text-gray-700">{label}</p>

        <div className="mt-4 flex flex-wrap gap-8">
          {edges.map((edge) => (
            <span className="flex items-center gap-3" key={edge}>
              <Checkbox
                checked={selectedEdgeOptions.includes(edge)}
                disabled={disabled}
                id={edge}
                onCheckedChange={(checked) => {
                  const isChecked = Boolean(checked)

                  if (isChecked) {
                    setSelectedEdgeOptions([...selectedEdgeOptions, edge])
                  } else {
                    const newOptions = selectedEdgeOptions.filter((option) => option !== edge)
                    setSelectedEdgeOptions(newOptions)
                  }
                }}
              />

              <label className={cn(disabled ? 'text-gray-300' : 'text-black')} htmlFor={edge}>
                {edge}
              </label>
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
