import Button from '../../Button'
import { ProductLibraryTabContentActions } from './ProductLibraryTabContentActions'

export function ProductLibraryTabContent() {
  return (
    <div>
      <div className="mb-10 flex flex-col gap-6 sm:flex-row sm:items-center">
        <span className="mr-auto text-black">
          <span className="font-medium">5 </span>
          classic frames active
        </span>

        <div>
          <Button>Add or edit variants</Button>
        </div>
      </div>

      <div className="overflow-auto">
        <table className="w-full border-separate">
          <thead className="text-sm text-gray-700">
            <tr>
              <th className="whitespace-nowrap border-b bg-white px-5 py-4 text-left">Image</th>
              <th className="whitespace-nowrap border-b bg-white px-5 py-4 text-left">Variant</th>
              <th className="whitespace-nowrap border-b bg-white px-5 py-4 text-left">Options</th>
              <th className="relative whitespace-nowrap border-b bg-white py-4 pl-5 pr-3 text-left">
                <span className="sr-only">Price</span>
              </th>
              <th className="relative whitespace-nowrap border-b bg-white py-4 pl-3 pr-0 text-left">
                <span className="sr-only">Actions</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {Array.from({ length: 8 }).map((_, index) => (
              <tr key={index}>
                <td className="whitespace-nowrap border-b bg-white px-5 py-4 align-top">
                  <img
                    alt=""
                    className="h-[80px] w-[65px] min-w-[65px] object-contain object-center"
                    src="/img/v2/product-catalogue/prints_fine-art-prints.jpg"
                  />
                </td>

                <td className="whitespace-nowrap border-b bg-white px-5 py-4">
                  <div className="font-medium">24x32”</div>
                  <div className="text-xs text-gray-600">GLOBAL-CFPM-24X32</div>
                </td>

                <td className="w-full whitespace-nowrap border-b bg-white px-5 py-4 align-top">
                  <div className="flex gap-1">
                    <div className="h-9 w-9 rounded-full border border-gray-200 bg-black"></div>
                    <div className="h-9 w-9 rounded-full border border-gray-200 bg-white"></div>
                    <div className="h-9 w-9 rounded-full border border-gray-200 bg-gray-500"></div>
                  </div>
                </td>

                <td className="whitespace-nowrap border-b bg-white py-4 pl-5 pr-3 align-top">
                  <div>
                    <div className="font-medium text-black">£32.00</div>
                    <div className="text-right text-xs text-gray-600">€38,04</div>
                  </div>
                </td>

                <td className="whitespace-nowrap border-b bg-white py-4 pl-3 pr-0 align-top">
                  <div className="flex justify-end">
                    <ProductLibraryTabContentActions />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
