import { useState } from 'react'
import { EllipsisHorizontalIcon, PhotoIcon } from '@heroicons/react/24/solid'
import { InformationCircleIcon, ShoppingCartIcon, SwatchIcon, TrashIcon } from '@heroicons/react/24/outline'

import { ProductLibraryTabContentEditOptions } from './ProductLibraryTabContentEditOptions.component'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../DropdownMenu'

export type TabContentActionsValueType = 'edit-options' | 'order-now' | 'variant-info' | 'edit-image' | 'delete'

export function ProductLibraryTabContentActions() {
  const [actionModalToOpen, setActionModalToOpen] = useState<TabContentActionsValueType | null>(null)

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="aspect-square w-auto border-purple-600">
          <EllipsisHorizontalIcon className="h-6 w-6 text-purple-600" style={{ transform: 'scale(1.5)' }} />
          <span className="sr-only">Actions</span>
        </DropdownMenuTrigger>

        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onSelect={() => {
              setActionModalToOpen('edit-options')
            }}
          >
            <SwatchIcon className="text-inherit h-6 w-6" />
            Edit options
          </DropdownMenuItem>

          <DropdownMenuItem>
            <ShoppingCartIcon className="text-inherit h-6 w-6" />
            Order now
          </DropdownMenuItem>

          <DropdownMenuItem>
            <InformationCircleIcon className="text-inherit h-6 w-6" />
            Variant info
          </DropdownMenuItem>

          <DropdownMenuItem>
            <PhotoIcon className="text-inherit h-6 w-6" />
            Edit variant image
          </DropdownMenuItem>

          <DropdownMenuItem className="border-t text-magenta-700">
            <TrashIcon className="text-inherit h-6 w-6" />
            Remove from this product
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <ProductLibraryTabContentEditOptions
        actionModalToOpen={actionModalToOpen}
        onSetActionModalToOpen={setActionModalToOpen}
      />
    </>
  )
}
