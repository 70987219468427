import toast from 'react-hot-toast'
import { ReactNode, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { BuildingLibraryIcon, CreditCardIcon } from '@heroicons/react/24/outline'

import Button from '../../../../../Button'
import { PayPalIcon } from '../../../../../svg'
import { useUser } from '../../../../../../hooks'
import { cn, fetcher } from '../../../../../../helpers'
import { createErrorToast } from '../../../../../Toast'
import OverlayPortal from '../../../../../OverlayPortal'
import { APP_ENVIRONMENTS } from '../../../../../../constants'
import { UpdatePaymentMethod } from './UpdatePaymentMethod.component'
import { CheckoutPaymentMethod } from './CheckoutPaymentMethod.component'
import { isBankDebitMethod } from '../../helpers/isBankDebitMethod.helper'
import { DEFAULT_STRIPE_OPTIONS, stripePromise } from '../../../../../../helpers/stripePromise.helper'
import { FetchErrorInterface, PaymentMethodOptionType, StatusType } from '../../../../../../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'
import { STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS } from '../../../../../../constants/stripePaymentMethodConfiuration.const'

type StripeIntentStateType =
  | {
      status: Omit<StatusType, 'success'>
    }
  | {
      status: 'success'
      clientSecret: string
    }

export function PaymentMethodOptions() {
  const { user } = useUser()

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentMethodOptionType | null>(null)
  const [stripeSetupIntent, setStripeSetupIntent] = useState<StripeIntentStateType>({ status: 'idle' })

  const hasPaymentSetup = user?.billing.paymentMethods && user?.billing.paymentMethods.length > 0
  const showBankDebitOptions =
    user?.billing.invoiceFrequency === 'Monthly' || user?.billing.invoiceFrequency === 'Weekly'

  async function handleSetupIntentPaymentMethodSelect(selectedPaymentOption: PaymentMethodOptionType) {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)

    setStripeSetupIntent({ status: 'loading' })
    setSelectedPaymentOption(selectedPaymentOption)

    try {
      const setupIntentUrl = new URL(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/requestsetupintent`)

      const configId =
        process.env.REACT_APP_ENV === APP_ENVIRONMENTS.LIVE
          ? STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS.DASHBOARD_DEFAULT.LIVE
          : STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS.DASHBOARD_DEFAULT.TEST
      setupIntentUrl.searchParams.set('configId', configId)

      const isBankDebitOption = selectedPaymentOption === 'us_bank_account' || selectedPaymentOption === 'sepa_debit'
      if (isBankDebitOption) {
        const configId =
          process.env.REACT_APP_ENV === APP_ENVIRONMENTS.LIVE
            ? STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS.WITH_DIRECT_DEBIT.LIVE
            : STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS.WITH_DIRECT_DEBIT.TEST
        setupIntentUrl.searchParams.set('configId', configId)
      }

      const response = await fetcher<{ data: string; statusCode: number; statusTxt: string }>(setupIntentUrl.toString())

      setStripeSetupIntent({ status: 'success', clientSecret: response.data })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        errorCode: errorResponse.status ?? errorResponse.message,
        heading: 'Failed to edit billing details',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setStripeSetupIntent({ status: 'error' })
      setSelectedPaymentOption(null)
    }
  }

  if (selectedPaymentOption === 'bacs_debit') {
    return (
      <div>
        <h3 className="mb-10 mt-0">Add new payment method</h3>
        <CheckoutPaymentMethod onClose={() => setSelectedPaymentOption(null)} />
      </div>
    )
  }

  if (stripeSetupIntent.status === 'success' && 'clientSecret' in stripeSetupIntent) {
    return (
      <div>
        <h3 className="mb-10 mt-0">Add new payment method</h3>
        <Elements
          stripe={stripePromise}
          options={{
            appearance: { variables: { colorPrimary: '#4630D4', fontFamily: 'objektiv-mk2' } },
            fonts: DEFAULT_STRIPE_OPTIONS.fonts,
            clientSecret: stripeSetupIntent.clientSecret
          }}
        >
          <UpdatePaymentMethod
            preferredPaymentMethod={selectedPaymentOption}
            stripeSetupIntentClientSecret={stripeSetupIntent.clientSecret}
            onClose={() => {
              setStripeSetupIntent({ status: 'idle' })
              setSelectedPaymentOption(null)
            }}
          />
        </Elements>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-20">
      <div>
        <h3 className="mb-10 mt-0">Add a card or PayPal</h3>

        <ul className="flex flex-col gap-4">
          <li>
            <PaymentMethodOption
              isLoading={stripeSetupIntent.status === 'loading' && selectedPaymentOption === 'card'}
              icon={<CreditCardIcon className="h-10 w-10 text-gray-500" />}
              type="card"
              onAdd={() => handleSetupIntentPaymentMethodSelect('card')}
            >
              Credit/debit card
            </PaymentMethodOption>
          </li>
          <li>
            <PaymentMethodOption
              isLoading={stripeSetupIntent.status === 'loading' && selectedPaymentOption === 'paypal'}
              icon={<PayPalIcon className="h-10 w-10 text-gray-500" type="grey" />}
              type="paypal"
              onAdd={() => handleSetupIntentPaymentMethodSelect('paypal')}
            >
              PayPal
            </PaymentMethodOption>
          </li>
        </ul>
      </div>

      {showBankDebitOptions && (
        <div>
          <h3 className="mt-0">Connect a bank account</h3>
          {!hasPaymentSetup && (
            <div className="mt-1 text-base text-gray-700">
              We require a card for validation before setting up bank payments.
            </div>
          )}

          <ul className="mt-10 flex flex-col gap-4">
            <li>
              <PaymentMethodOption
                icon={
                  <div className="relative">
                    <BuildingLibraryIcon className="h-10 w-10 text-gray-500" />
                    <div
                      className="absolute -right-5 -top-5 h-7 w-7 rounded-full bg-cover bg-center bg-no-repeat"
                      style={{ backgroundImage: `url("/img/flags-100/gb.png")` }}
                    ></div>
                  </div>
                }
                type="bacs_debit"
                onAdd={() => setSelectedPaymentOption('bacs_debit')}
              >
                UK-based account
              </PaymentMethodOption>
            </li>
            <li>
              <PaymentMethodOption
                isLoading={stripeSetupIntent.status === 'loading' && selectedPaymentOption === 'us_bank_account'}
                icon={
                  <div className="relative">
                    <BuildingLibraryIcon className="h-10 w-10 text-gray-500" />
                    <div
                      className="absolute -right-5 -top-5 h-7 w-7 rounded-full bg-cover bg-center bg-no-repeat"
                      style={{ backgroundImage: `url("/img/flags-100/us.png")` }}
                    ></div>
                  </div>
                }
                type="us_bank_account"
                onAdd={() => handleSetupIntentPaymentMethodSelect('us_bank_account')}
              >
                US-based account
              </PaymentMethodOption>
            </li>
            <li>
              <PaymentMethodOption
                isLoading={stripeSetupIntent.status === 'loading' && selectedPaymentOption === 'sepa_debit'}
                icon={
                  <div className="relative">
                    <BuildingLibraryIcon className="h-10 w-10 text-gray-500" />
                    <div
                      className="absolute -right-5 -top-5 h-7 w-7 rounded-full bg-cover bg-center bg-no-repeat"
                      style={{ backgroundImage: `url("/img/flags-100/eu.png")` }}
                    ></div>
                  </div>
                }
                type="sepa_debit"
                onAdd={() => handleSetupIntentPaymentMethodSelect('sepa_debit')}
              >
                EU-based account
              </PaymentMethodOption>
            </li>
          </ul>
        </div>
      )}

      {stripeSetupIntent.status === 'loading' && <OverlayPortal />}
    </div>
  )
}

function PaymentMethodOption({
  icon,
  children,
  isLoading = false,
  type,
  onAdd
}: {
  icon: ReactNode
  children: string
  isLoading?: boolean
  type: PaymentMethodOptionType
  onAdd: () => void
}) {
  const { user } = useUser()
  const hasPaymentSetup = user?.billing.paymentMethods && user?.billing.paymentMethods.length > 0
  const isBankDebit = isBankDebitMethod(type)
  const isDisabled = isBankDebit ? !hasPaymentSetup : false

  return (
    <div className={cn('flex items-center gap-4 p-4 sm:gap-6', isDisabled && 'opacity-60')}>
      <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gray-100">{icon}</div>

      <div className="flex-1 font-medium" style={{ wordBreak: 'break-word' }}>
        {children}
      </div>

      <div>
        <Button
          disabled={isDisabled}
          isLoading={isLoading}
          theme="brand"
          variant={hasPaymentSetup || isBankDebit ? 'secondary' : 'primary'}
          onClick={onAdd}
        >
          Add
        </Button>
      </div>
    </div>
  )
}
