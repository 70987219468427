import { AnimatePresence, motion } from 'motion/react'
import { BuildingLibraryIcon, CreditCardIcon } from '@heroicons/react/24/outline'

import FormItem from '../../../../../FormItem'
import { PayPalIcon } from '../../../../../svg'
import TextField from '../../../../../TextField'
import { useUser } from '../../../../../../hooks'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { PaymentMethodOptions } from './PaymentMethodOptions.component'
import { isBankDebitMethod } from '../../helpers/isBankDebitMethod.helper'
import { PaymentMethodIdentifier } from './PaymentMethodIdentifier.component'
import { BillingStripeRedirectModal } from './BillingStripeRedirectModal.component'
import { cn, formatToSentenceCase, splitByPascalCase } from '../../../../../../helpers'
import { PaymentMethodOptionType, PaymentMethodType } from '../../../../../../interfaces'
import { MakePaymentMethodPrimaryButton } from './MakePaymentMethodPrimaryButton.component'
import { RemovePaymentMethodModalButton } from './RemovePaymentMethodModalButton.component'
import { CheckoutSetupStripeRedirectModal } from './CheckoutSetupStripeRedirectModal.component'

export function PaymentMethodDetails() {
  const { user } = useUser()

  if (!user) {
    return <>No user</>
  }

  return (
    <>
      <SettingsPanel
        className="border-x"
        title={<h2>Payment method</h2>}
        subtitle={
          <div className="mt-4 max-w-lg text-gray-600">
            A valid card or PayPal registration is required for all accounts, even if your primary payment method is via
            a bank account.
          </div>
        }
      >
        <div className="max-w-2xl">
          <div className="flex flex-col gap-10">
            {user.billing.paymentMethods.map((paymentMethod) => (
              <PaymentMethodInfo key={paymentMethod.paymentMethodId} paymentMethod={paymentMethod} />
            ))}
          </div>

          {user.billing.paymentMethods.length < 2 && (
            <div className={cn(user.billing.paymentMethods.length > 0 && 'mt-20')}>
              <PaymentMethodOptions />
            </div>
          )}
        </div>
      </SettingsPanel>

      <SettingsPanel className="border" title={<h2>Invoices</h2>}>
        <FormItem
          inputField={
            <TextField
              className="max-w-xl"
              disabled
              value={formatToSentenceCase(splitByPascalCase(user.billing.invoiceFrequency))}
              type="text"
            />
          }
          labelClassName="mt-0 pt-0"
          labelText="Invoice frequency"
        />
      </SettingsPanel>

      <BillingStripeRedirectModal />

      <CheckoutSetupStripeRedirectModal />
    </>
  )
}

function PaymentMethodInfo({ paymentMethod }: { paymentMethod: PaymentMethodType }) {
  const { user } = useUser()

  const isPrimary = paymentMethod.isPrimary || user?.billing.paymentMethods.length === 1

  return (
    <div className="flex items-center gap-4 rounded-lg bg-[#F9F9F9] p-4 sm:gap-6">
      <div className="flex h-20 w-20 items-center justify-center">
        <PaymentMethodIcon type={paymentMethod.type} />
      </div>

      <div className="flex-1">
        <div className="font-medium" style={{ wordBreak: 'break-word' }}>
          <PaymentMethodIdentifier identifier={paymentMethod.identifier} type={paymentMethod.type} />
        </div>

        {isBankDebitMethod(paymentMethod.type) && paymentMethod.mandateStatus && (
          <div className="text-xs capitalize text-gray-700">{paymentMethod.mandateStatus}</div>
        )}

        <div className="mt-2">
          <RemovePaymentMethodModalButton paymentMethod={paymentMethod} />
        </div>
      </div>

      <div className="relative self-start">
        <AnimatePresence initial={false} mode="popLayout">
          <motion.div
            key={isPrimary.toString()}
            initial={{ opacity: 0, scale: 0.6, filter: 'blur(4px)' }}
            animate={{ opacity: 1, scale: 1, filter: 'blur(0px)' }}
            exit={{ opacity: 0, scale: 0.6, filter: 'blur(4px)' }}
            transition={{ bounce: 0, duration: 0.3, type: 'spring' }}
          >
            {isPrimary ? (
              <div className="rounded-lg bg-green-100 px-4 py-1 text-xs text-green-800">primary</div>
            ) : (
              <div>
                <MakePaymentMethodPrimaryButton paymentMethod={paymentMethod} />
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}

function PaymentMethodIcon({ type }: { type?: PaymentMethodOptionType }) {
  switch (type) {
    case 'paypal':
      return <PayPalIcon className="h-12 w-12" type="colour" />
    case 'card':
      return <CreditCardIcon className="h-12 w-12" />
    case 'bacs_debit':
      return (
        <div className="relative">
          <BuildingLibraryIcon className="h-10 w-10" />
          <div
            className="absolute -right-5 -top-5 h-7 w-7 rounded-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url("/img/flags-100/gb.png")` }}
          ></div>
        </div>
      )
    case 'us_bank_account':
      return (
        <div className="relative">
          <BuildingLibraryIcon className="h-10 w-10" />
          <div
            className="absolute -right-5 -top-5 h-7 w-7 rounded-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url("/img/flags-100/us.png")` }}
          ></div>
        </div>
      )
    case 'sepa_debit':
      return (
        <div className="relative">
          <BuildingLibraryIcon className="h-10 w-10" />
          <div
            className="absolute -right-5 -top-5 h-7 w-7 rounded-full bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url("/img/flags-100/eu.png")` }}
          ></div>
        </div>
      )
    default:
      return null
  }
}
