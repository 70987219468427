// @flow
import { createV4Order } from '../orders'
import type {
  Cost,
  GetStateFunc,
  MultiAssetBasketItem,
  V4OrderItem,
  Dictionary,
  V4ProductDetails,
  V4OrderCreationRequest,
  DispatchFunc,
  CustomerAddress,
  OrderItemAsset,
  InProgressOrder,
  PackingSlip,
  ThunkAsync,
  Thunk,
  V4OrderCreationResponse,
  BasketItemMetaDataType
} from '../../types'
import {
  selectIsUsSalesTaxAlreadyCollected,
  selectInProgressOrder,
  selectCustomer,
  selectPackingSlip,
  selectSelectedShippingMethodName
} from '../../selectors/manualOrderForm'
import { getV4ProductDetailsBySkus } from '../../selectors/catalogue'
import { selectCurrencyCode } from '../../selectors/user'
import type { OnV4MofOrderCreationSuccess } from '../types'
import { saveRecentItems } from '../recentItems'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import { V4_ERROR_CODE } from '../../data/v4ErrorCode'
import { logLe } from '../logging'
import { pushRedirect } from '../router'
import { mapCustomerAddressToRecipient } from '../../helpers'
import { mapV3AttributesToV4 } from '../../helpers/attributes'
import { isPaymentRequired } from '../../helpers/isPaymentRequired'
import { nanoid } from 'nanoid'
import { addMultipleBasketItems } from './addMultipleBasketItems'
import { setCustomerAddress } from './setCustomerAddress'
import { convertV4OrderIdToV3OrderId } from '../../helpers/orderId/convertV4OrderIdToV3OrderId'
import type { InsertDataType } from '../../types/branding'
import type { V4OrderInsertsType } from '../../types/v4OrderCreationRequest'

// $FlowFixMe: TypeScript file
import { QUERY_PARAMS, ROUTE_PATHS } from 'src/v2/constants'

export const MOF_ORDER_LOGGER_ID = 'MOF'
export const V4_MOF_ORDER_CREATION_SUCCESS = 'V4_MOF_ORDER_CREATION_SUCCESS'

export type CreateMofOrderDataType = {|
  brandedInserts?: InsertDataType[],
  isTransformUrlsOn?: boolean
|}

export function createMofOrder({
  brandedInserts = [],
  isTransformUrlsOn = false
}: CreateMofOrderDataType): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const inProgressOrder = selectInProgressOrder(getState())
    const recipientAddress = selectCustomer(getState())
    const selectedShippingMethodName = selectSelectedShippingMethodName(getState())
    const allSkus = inProgressOrder.itemsToBeCreated.map((item) => item.sku)
    const productDetails = getV4ProductDetailsBySkus(getState(), allSkus)
    const packingSlip = selectPackingSlip(getState())
    const userCurrency = selectCurrencyCode(getState())
    const isUsSalesTaxAlreadyCollected = selectIsUsSalesTaxAlreadyCollected(getState())

    if (!selectedShippingMethodName) {
      throw Error('selectedShippingMethodName cannot be null')
    }

    const requestBody = buildRequestBody(
      inProgressOrder,
      recipientAddress,
      selectedShippingMethodName,
      productDetails,
      userCurrency,
      packingSlip,
      isUsSalesTaxAlreadyCollected,
      isTransformUrlsOn,
      brandedInserts
    )

    dispatch(
      logV4OrderCreation(inProgressOrder.itemsToBeCreated, recipientAddress.countryCode, selectedShippingMethodName)
    )

    const actionResult = await dispatch(createV4Order(requestBody, MOF_ORDER_LOGGER_ID))

    dispatch(
      onOrderCreation(actionResult.payload, actionResult.type, inProgressOrder.itemsToBeCreated, recipientAddress)
    )
  }
}

function logV4OrderCreation(
  basketItems: MultiAssetBasketItem[],
  deliveryCountry: string,
  selectedShippingMethodName: string
): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    if (window.analytics) {
      window.analytics.track('Create order', { label: 'MOF' })
    }

    const orderDetails = {
      deliveryCountry,
      selectedShippingMethodName,
      items: basketItems
    }
    dispatch(logLe('Creating v4 order', orderDetails))
  }
}

function onOrderCreation(
  response: V4OrderCreationResponse,
  orderActionType: string,
  basketItems: MultiAssetBasketItem[],
  address: CustomerAddress
): ThunkAsync<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const isResponseSuccessful = Boolean(
      response.outcome === V4_API_ACTION_OUTCOME.CREATED ||
        response.outcome === V4_API_ACTION_OUTCOME.CREATED_WITH_ISSUES ||
        response.outcome === V4_API_ACTION_OUTCOME.ON_HOLD
    )

    if (!isResponseSuccessful) {
      const mappedBasketItems: MultiAssetBasketItem[] = resetBasketItemIds(basketItems)
      dispatch(
        addMultipleBasketItems({
          basketItems: mappedBasketItems,
          lastBasketItemId: mappedBasketItems.length - 1
        })
      )
      dispatch(setCustomerAddress(address))
      return
    }

    dispatch(saveRecentItems(basketItems))
    dispatch(onV4MofOrderCreationSuccess(response.order.id))

    if (isPaymentRequired(response)) {
      dispatch(redirectToPaymentPage(response))
    }
  }
}

function resetBasketItemIds(basketItems: MultiAssetBasketItem[]): MultiAssetBasketItem[] {
  return basketItems.map((basketItem, index) => {
    return {
      ...basketItem,
      id: index
    }
  })
}

function redirectToPaymentPage(response: V4OrderCreationResponse): Thunk<*> {
  return (dispatch: DispatchFunc) => {
    const paymentIssue = response.order.status.issues.find(
      (issue) => issue.errorCode === V4_ERROR_CODE.PAYMENT_REQUIRED
    )

    if (!paymentIssue) {
      return
    }

    const orderIdWithoutPrefix = convertV4OrderIdToV3OrderId(response.order.id)
    const paymentPath = `${ROUTE_PATHS.PAYMENT_AUTHORISATION}?${QUERY_PARAMS.PAYMENT.ORDER_ID}=${orderIdWithoutPrefix}&${QUERY_PARAMS.PAYMENT.FULFILMENT_ID}=${orderIdWithoutPrefix}`

    dispatch(pushRedirect(paymentPath))
  }
}

function onV4MofOrderCreationSuccess(orderId: string): OnV4MofOrderCreationSuccess {
  return {
    type: V4_MOF_ORDER_CREATION_SUCCESS,
    orderId
  }
}

function buildRequestBody(
  mofOrder: InProgressOrder,
  customerAddress: CustomerAddress,
  selectedShippingMethodName: ?string,
  productDetails: Dictionary<V4ProductDetails>,
  userCurrency: string,
  packingSlip: ?PackingSlip,
  isUsSalesTaxAlreadyCollected: boolean,
  isTransformUrlsOn: boolean,
  brandedInserts: InsertDataType[]
): V4OrderCreationRequest {
  if (!selectedShippingMethodName) {
    throw Error('Shipping method name cannot be null.')
  }

  return {
    tag: 'source:MOF',
    merchantReference: mofOrder.merchantOrderId ? mofOrder.merchantOrderId : null,
    packingSlip: packingSlip ? { url: packingSlip.url } : null,
    shippingMethod: selectedShippingMethodName,
    items: mapOrderItems(mofOrder.itemsToBeCreated, productDetails, userCurrency, isTransformUrlsOn),
    branding: mapInserts(brandedInserts),
    recipient: mapCustomerAddressToRecipient(customerAddress),
    idempotencyKey: nanoid(),
    USSalesTaxAlreadyCollected: isUsSalesTaxAlreadyCollected,
    metadata: mapMetaData(mofOrder.itemsToBeCreated)
  }
}

function mapMetaData(basketItems: MultiAssetBasketItem[]): ?{| prodigiItems: Dictionary<BasketItemMetaDataType> |} {
  const metaData = { prodigiItems: {} }

  basketItems.forEach((basketItem) => {
    if (!basketItem.metaData) {
      return
    }

    metaData.prodigiItems[basketItem.id] = basketItem.metaData
  })

  return Object.keys(metaData?.prodigiItems ?? {}).length > 0 ? metaData : undefined
}

function mapInserts(brandedInserts: InsertDataType[]): V4OrderInsertsType | typeof undefined {
  if (brandedInserts.length === 0) {
    return undefined
  }

  const orderInserts: V4OrderInsertsType = {}
  brandedInserts.forEach((insert) => {
    if (!insert.imageUrl) {
      return
    }
    orderInserts[insert.id] = { url: insert.imageUrl }
  })

  if (Object.keys(orderInserts).length === 0) {
    return undefined
  }

  return orderInserts
}

function mapOrderItems(
  basketItems: MultiAssetBasketItem[],
  productDetails: Dictionary<V4ProductDetails>,
  userCurrency: string,
  isTransformUrlsOn: boolean
): V4OrderItem[] {
  return basketItems.map((basketItem) => {
    return {
      sku: basketItem.sku,
      copies: basketItem.quantity,
      sizing: 'fillPrintArea',
      assets: mapOrderItemWithMultipleAssets(basketItem, productDetails[basketItem.sku], isTransformUrlsOn),
      attributes: mapV3AttributesToV4(
        basketItem.selectedAttributes,
        productDetails[basketItem.sku],
        basketItem?.costedAttributeKeys?.value ?? []
      ),
      recipientCost: basketItem.priceToUserAsInt ? mapRecipientCost(basketItem.priceToUserAsInt, userCurrency) : null,
      merchantReference: basketItem.id.toString()
    }
  })
}

function mapOrderItemWithMultipleAssets(
  basketItem: MultiAssetBasketItem,
  productDetails: V4ProductDetails,
  isTransformUrlsOn: boolean
): OrderItemAsset[] {
  if (!basketItem.printAreas) {
    throw Error(`Print areas cannot be null, basketItem ${basketItem.id} - ${basketItem.sku}`)
  }
  const basketItemPrintAreas = basketItem.printAreas
  const printAreasWithArtworks = Object.keys(basketItemPrintAreas).filter((printAreaName) =>
    Boolean(basketItemPrintAreas[printAreaName].artwork)
  )

  return printAreasWithArtworks.map((printAreaName) => {
    const imageUrl = basketItemPrintAreas[printAreaName]?.artwork?.croppedImageUrl

    if (!imageUrl) {
      throw Error(`imageUrl cannot be null printArea - ${printAreaName}`)
    }

    const transformImageUrl = basketItemPrintAreas[printAreaName]?.artwork?.transformImageUrl ?? imageUrl

    return {
      printArea: printAreaName,
      url: isTransformUrlsOn ? transformImageUrl : imageUrl,
      pageCount:
        basketItemPrintAreas[printAreaName]?.artwork?.pageCount &&
        basketItemPrintAreas[printAreaName]?.artwork?.pageCount > 0
          ? basketItemPrintAreas[printAreaName]?.artwork?.pageCount
          : undefined,
      additionalUiDetail: {
        artwork: basketItemPrintAreas[printAreaName]?.artwork ?? undefined,
        artworkTransformations: basketItemPrintAreas[printAreaName]?.artworkTransformations ?? undefined,
        printImageUrl: isTransformUrlsOn ? transformImageUrl : imageUrl,
        previewImageUrl: isTransformUrlsOn ? transformImageUrl : imageUrl,
        transformImageUrl: transformImageUrl
      },
      additionalUiDetailVersion: '1.0'
    }
  })
}

function mapRecipientCost(priceToUserAsInt: number, currencyCode: string): Cost {
  return {
    amount: (priceToUserAsInt / 100).toFixed(2),
    currency: currencyCode
  }
}
