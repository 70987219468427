import { useLocalStorage } from '@mantine/hooks'
import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'motion/react'
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar'
import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'

import Button from '../Button'
import { Checkbox } from '../Checkbox'
import { createToast } from '../Toast'
import ImageLibraryActions from '../ImageLibraryActions'
import ImageLibraryAppliedFilters from '../ImageLibraryAppliedFilters'
import { ImageList, UploadChoiceDisplay, UploadOptionValueType, UploadOptions } from './components'
import { ImageLibraryImageInterface, ImageLibraryImageType, useImageLibraryImages } from '../../hooks'

export type ImageLibraryModeType = 'select' | 'manage'

export interface ImageLibraryConfigPropInterface {
  forceSaveToLibrary?: boolean
}

export interface ImageLibraryPropsInterface {
  config?: ImageLibraryConfigPropInterface
  type: ImageLibraryImageType
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export default function ImageLibrary({ config = {}, type, onSelectImage }: ImageLibraryPropsInterface) {
  const { imageLibraryPages, isLoadingImageLibraryImages } = useImageLibraryImages()

  const [imageLibraryMode, setImageLibraryMode] = useState<ImageLibraryModeType>('select')
  const [isSaveToLibraryChecked, setIsSaveToLibraryChecked] = useLocalStorage({
    key: 'prodigiImageLibrarySaveCheckbox',
    defaultValue: true,
    getInitialValueInEffect: false
  })
  const [uploadChoice, setUploadChoice] = useLocalStorage<UploadOptionValueType>({
    key: 'prodigiImageLibraryUploadChoice',
    defaultValue: 'file',
    getInitialValueInEffect: false
  })

  const loadingBarRef = useRef<LoadingBarRef>(null)

  const isImageListEmpty = imageLibraryPages?.[0]?.length === 0

  useEffect(() => {
    if (isImageListEmpty) {
      setImageLibraryMode('select')
    }
  }, [isImageListEmpty])

  function handleUploadStart() {
    loadingBarRef.current?.continuousStart()
  }

  function handleUploadComplete() {
    loadingBarRef.current?.complete()
  }

  function handleSelectImage(imageLibraryImage: ImageLibraryImageInterface) {
    if (imageLibraryImage.is_truncated) {
      createToast({
        content:
          'Your file is truncated and may not print correctly. Please check it carefully before submitting your order.',
        duration: 6500,
        heading: 'File may be corrupted',
        iconTheme: {
          primary: '#FC7B1E',
          secondary: 'white'
        },
        type: 'success-with-close'
      })
    }
    onSelectImage?.(imageLibraryImage)
  }

  return (
    <>
      <div className="sticky top-0 z-10 bg-white pb-8 pt-2">
        <header className="flex flex-row justify-between gap-2">
          <div className="flex flex-col flex-wrap gap-6 sm:flex-row sm:items-center">
            <UploadOptions
              manageLibrary={imageLibraryMode === 'manage'}
              uploadChoice={uploadChoice}
              setUploadChoice={setUploadChoice}
            >
              <UploadChoiceDisplay
                key={uploadChoice}
                isSaveToLibraryChecked={config.forceSaveToLibrary ? true : isSaveToLibraryChecked}
                type={type}
                uploadChoice={uploadChoice}
                onUploadStart={handleUploadStart}
                onUploadComplete={handleUploadComplete}
                onSelectImage={handleSelectImage}
              />
            </UploadOptions>

            {!config.forceSaveToLibrary && (
              <div className="flex items-start gap-4">
                <Checkbox
                  id="save-to-library"
                  checked={isSaveToLibraryChecked}
                  data-test="save-to-library-checkbox"
                  onCheckedChange={(isChecked) => {
                    window.analytics.track('Image library - clicked: Save checkbox', { value: isChecked })
                    setIsSaveToLibraryChecked(Boolean(isChecked))
                  }}
                />

                <label htmlFor="save-to-library">Also save to library</label>
              </div>
            )}
          </div>

          <AnimatePresence>
            {!isImageListEmpty && !isLoadingImageLibraryImages && (
              <motion.div
                className="sm:ml-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {imageLibraryMode === 'select' && (
                  <Button
                    variant="secondary"
                    theme="brand"
                    onClick={() => {
                      window.analytics.track('Image library - clicked: Manage images')
                      setImageLibraryMode('manage')
                    }}
                  >
                    <span className="hidden md:inline">Select</span>
                    <span className="md:hidden">
                      <PencilIcon className="h-8 w-8" />
                    </span>
                  </Button>
                )}

                {imageLibraryMode === 'manage' && (
                  <Button variant="primary" theme="brand" onClick={() => setImageLibraryMode('select')}>
                    <span className="hidden md:inline">Done</span>
                    <span className="md:hidden">
                      <CheckIcon className="h-8 w-8" />
                    </span>
                  </Button>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </header>

        <div className="relative mt-4 h-2 bg-gray-100 md:mt-8">
          <LoadingBar
            ref={loadingBarRef}
            color="#866FF0"
            className="h-2 will-change-transform"
            containerStyle={{ position: 'absolute', height: 'inherit' }}
            shadow={false}
          />
        </div>

        <ImageLibraryActions />
        <ImageLibraryAppliedFilters />
      </div>

      <ImageList imageLibraryMode={imageLibraryMode} onSelectImage={handleSelectImage} />
    </>
  )
}
